import { Button, Card, Center, Grid, Text, Title } from '@mantine/core';
import logo from 'images/large/logo_colors_black.png';
import Image from 'next/image';
import { useSearchParams } from 'next/navigation';
import { signIn } from 'next-auth/react';

import { SupportLink } from 'components/ui/SupportLink';

import styles from './Error.module.css';

interface ErrorProps {
  error?: string;
}

// From https://next-auth.js.org/configuration/pages#error-page
// The following errors are passed as error query parameters to the default or overridden error page:
// Configuration: There is a problem with the server configuration. Check if your options are correct.
// AccessDenied: Usually occurs, when you restricted access through the signIn callback, or redirect callback
// Verification: Related to the Email provider. The token has expired or has already been used
// Default: Catch all, will apply, if none of the above matched

const getErrorMessages = (error: string) => {
  if (error === 'Verification') {
    return [
      'Unable to sign in',
      'The sign in link is no longer valid.',
      'It may have been used already or it may have expired.',
      'Sign in again',
    ];
  }
  if (error === 'DistributionList') {
    return [
      'Access Denied',
      'The company distribution email is not valid for login.',
      'Please log in with your individual company email address.',
      'Sign in again',
    ];
  }
  if (error === 'AccessDenied') {
    return [
      'Access Denied',
      'You do not have permission to sign in. Your account may have been disabled by an administrator. If you think this is an error, contact customer support.',
      'Try to sign in again',
    ];
  }
  if (error === 'EmailSignin') {
    return [
      'Unable to sign in',
      'There was a problem connecting to the login provider.',
      'Try to sign in again',
    ];
  }
  if (error === 'EmailCreateAccount') {
    return [
      'Unable to sign up',
      'There was a problem creating your account.',
      'Try to sign up again',
    ];
  }
  // Default
  return [
    'Something went wrong',
    'Sorry, an error occurred. You can try to sign in again or contact us at support@biggerpicture.co',
    'Try to sign in again',
  ];
};

export const Error = ({ error }: ErrorProps) => {
  const searchParams = useSearchParams();
  const paramsError = searchParams?.get('error');

  const errorMessages = getErrorMessages(error || paramsError || 'default');
  const title = errorMessages.shift();
  const action = errorMessages.pop();
  return (
    <Card withBorder p={24}>
      <Grid gutter={10} justify="center">
        <Grid.Col span={12}>
          <div className={styles.wrapper}>
            <Image
              src={logo}
              alt="BiggerPicture"
              fill
              style={{ objectFit: 'contain', padding: 'var(--logo-padding)' }}
            />
          </div>
        </Grid.Col>
        <Grid.Col span={12} className={styles.paragraph}>
          <Title>{title}</Title>
        </Grid.Col>
        {errorMessages.map((message, index) => {
          return (
            <Grid.Col span={12} key={index} className={styles.paragraph}>
              <Text>{message}</Text>
            </Grid.Col>
          );
        })}
        <Grid.Col span={12} className={styles.paragraph}>
          <Button
            onClick={() => {
              void signIn();
            }}
          >
            {action}
          </Button>
        </Grid.Col>
        <Grid.Col span={12} />
        <Grid.Col>
          <Center>
            <SupportLink />
          </Center>
        </Grid.Col>
      </Grid>
    </Card>
  );
};
